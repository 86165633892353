<div class="Graph">
    <line :chartdata="chartdata" :options="options"/>
</div>

<script>
import { Line } from 'vue-chartjs'

export default {
    name: "Graph",
    extends: Line,
    props: {
        chartdata: {
            type: Object,
            default: null
        },
        options: {
            type: Object,
            default: null
        }
    },
    mounted () {
        this.renderChart(this.chartdata, this.options)
    }
}
</script>

<style scoped lang="scss">
#line-chart{
    max-width: 100%;
    max-height: 332px;
}
</style>