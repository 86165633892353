<template>
    <div class="ReportGraph full-height pa-4 d-flex flex-column ">
        <div class="d-flex full-height-footerButtons">
            <v-dialog ref="pathdialog" v-model="pathmodal" :return-value.sync="exportPath" persistent width="500px">
                <div>
                    <div class="dialogcontainer pa-8">
                        <p style="font-weight: 500">{{ $t('report.selectPathExport') }}</p>
                        <v-list>
                            <v-list-item-group
                                v-model="exportPath"
                                color="disiIrisblue"
                                class="list mt-4 mb-6"
                                mandatory
                                id="pathmodal-list"
                            >
                                <v-list-item
                                    v-for="(item) in this.pathlist"
                                    :key="item.id"
                                    :value="item.id"
                                >
                                    <v-list-item-content>
                                        <v-list-item-title v-text="item.name"></v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list-item-group>
                        </v-list>

                        <div class="d-flex align-center mt-0 mb-8">
                            <switch-on-off name="graphOnReport-export" :class="{'active': exportGraph}" class="mr-4"/>
                            <p class="ml-3">{{ $t('report.exportGraph') }}</p>
                        </div>

                        <div class="button-container d-flex justify-space-between">
                            <v-btn elevation="0" class="content-btn small" @click="pathmodal = false;">
                                {{ $t('footerButton.cancel') }}
                            </v-btn>
                            <v-btn elevation="0" class="content-btn small" :disabled="exportPath == ''"
                                   @click="$refs.pathdialog.save(exportPath); doExport()">{{ $t('footerButton.save') }}
                            </v-btn>
                        </div>
                    </div>
                </div>
            </v-dialog>
            <v-dialog ref="printdialog" v-model="printmodal" persistent width="500px">
                <div>
                    <div class="dialogcontainer pa-8">
                        <div class="d-flex align-center mt-0 mb-8">
                            <switch-on-off name="graphOnReport-print" :class="{'active': printGraph}" class="mr-4"/>
                            <p class="ml-3">{{ $t('report.printGraph') }}</p>
                        </div>
                        <div class="button-container d-flex justify-space-between">
                            <v-btn elevation="0" class="content-btn small" @click="printmodal = false;">
                                {{ $t('footerButton.cancel') }}
                            </v-btn>
                            <v-btn elevation="0" class="content-btn small" @click="printReport(); printmodal = false;">
                                {{ $t('footerButton.print') }}
                            </v-btn>
                        </div>
                    </div>
                </div>
            </v-dialog>
            <navigation-left-big/>
            <div class="content-area pa-0 flex-grow-1 bg-white d-flex flex-column">
                <div class="title-bar d-flex pa-4 align-center mb-2">
                    <p>{{ report.testInfo.productName }}</p>
                    <v-spacer/>
                    <p class="testTime ml-8 d-flex align-center">{{ report.dateTime }}</p>
                </div>
                <p class="flex-grow-1 text-center d-flex justify-center align-center pb-6 noGraph"
                   v-if="showNoGraphMessage"> {{ $t('report.notEnoughDataToBuildGraph') }} </p>
                <graph class="pa-4" v-if="loaded"
                       :chart-data="chartdata"
                       :chartdata="chartdata"
                       :options="chartoptions">
                </graph>
            </div>
        </div>

        <v-bottom-navigation grow dark background-color="disiBluegreyLight" class="pt-4" height="76">
            <footer-button buttonFunction="back" @footerButtonClick="goToReport"></footer-button>
            <footer-button></footer-button>
            <footer-button></footer-button>
            <footer-button buttonFunction="delete"
                           :class="{'disabled': !checkUserRights('reportsDelete')}"
                           @footerButtonClick="deleteReport"></footer-button>
            <footer-button buttonFunction="export" :successCheck="showExportSuccess" :progress="showExportProgress"
                           @footerButtonClick="pathmodal = true"
                           :class="{'disabled': !checkUserRights('reportsExport')}"></footer-button>
            <footer-button buttonFunction="print" :successCheck="showPrintSuccess" :progress="showPrintProgress"
                           @footerButtonClick="printmodal = true"
                           :class="{'disabled': !checkUserRights('reportsPrint')}"></footer-button>
        </v-bottom-navigation>
    </div>
</template>

<script>
import NavigationLeftBig from '@/components/NavigationLeftBig.vue'
import Graph from '@/components/Graph.vue'
import FooterButton from '@/components/FooterButton.vue'
import {mapState} from "vuex";
import mixins from "@/mixins/mixins";
import SwitchOnOff from "@/components/SwitchOnOff.vue";

export default {
    name: 'ReportGraph',
    components: {
        NavigationLeftBig,
        SwitchOnOff,
        Graph,
        FooterButton,
    },
    props: {},
    data() {
        return {
            showPrintProgress: false,
            showPrintSuccess: false,
            showExportProgress: false,
            showExportSuccess: false,
            showNoGraphMessage: false,
            pathmodal: false,
            exportPath: '',
            printmodal: false,
            loaded: false,
            chartdata: null,
            chartoptions: {
                responsive: true,
                maintainAspectRatio: false,
                legend: {
                    display: true,
                    position: 'bottom',
                    labels: {
                        boxWidth: 8,
                        padding: 30,
                        usePointStyle: true,
                        fontSize: 17,
                        fontColor: '#3C3C3C',
                        fontFamily: "'Gilroy', sans-serif",
                        fontStyle: "300",
                    }
                },
                scales: {
                    xAxes: [{
                        xAxisID: "timestamp",
                        ticks: {
                            fontSize: 17,
                            fontColor: '#3C3C3C',
                            fontFamily: "'Gilroy', sans-serif",
                            fontStyle: "300",
                            padding: 12,
                        }
                    }],
                    yAxes: [{
                        yAxisID: "disintegration",
                        ticks: {
                            fontSize: 17,
                            fontColor: '#3C3C3C',
                            fontFamily: "'Gilroy', sans-serif",
                            fontStyle: "300",
                            padding: 12,
                            stepSize: 20,
                            beginAtZero: true,
                            callback: function (value) {
                                return value + '%';
                            }
                        }
                    }],
                },
                tooltips: {
                    enabled: false,
                    backgroundColor: '#3D6D7D',
                    bodyFontFamily: "'Gilroy', sans-serif",
                    bodyFontSize: 20,
                    bodySpacing: 12,
                    titleFontFamily: "'Gilroy', sans-serif",
                    titleFontSize: 20,
                    titleFontStyle: 'regular',
                    footerFontFamily: "'Gilroy', sans-serif",
                    footerFontSize: 20,
                    xPadding: 12,
                    yPadding: 12,
                    custom: function (tooltipModel) {
                        // Tooltip Element
                        var tooltipEl = document.getElementById('chartjs-tooltip');
                        // Create element on first render
                        if (!tooltipEl) {
                            tooltipEl = document.createElement('div');
                            tooltipEl.id = 'chartjs-tooltip';
                            tooltipEl.innerHTML = '<table></table>';
                            document.body.appendChild(tooltipEl);
                        }
                        // Hide if no tooltip
                        if (tooltipModel.opacity === 0) {
                            tooltipEl.style.opacity = 0;
                            return;
                        }
                        // Set caret Position
                        tooltipEl.classList.remove('above', 'below', 'no-transform');
                        if (tooltipModel.yAlign) {
                            tooltipEl.classList.add(tooltipModel.yAlign);
                        } else {
                            tooltipEl.classList.add('no-transform');
                        }

                        function getBody(bodyItem) {
                            return bodyItem.lines;
                        }

                        // Set Text
                        if (tooltipModel.body) {
                            var titleLines = tooltipModel.title || [];
                            var bodyLines = tooltipModel.body.map(getBody);
                            var innerHtml = '<thead>';
                            titleLines.forEach(function (title) {
                                innerHtml += '<tr><th>' + title + '</th></tr>';
                            });
                            innerHtml += '</thead><tbody>';
                            bodyLines.forEach(function (body, i) {
                                var colors = tooltipModel.labelColors[i];
                                var style = 'background:' + colors.backgroundColor;
                                var span = '<span style="' + style + '"></span>';
                                innerHtml += '<tr><td>' + span + body + '%</td></tr>';
                            });
                            innerHtml += '</tbody>';
                            var tableRoot = tooltipEl.querySelector('table');
                            tableRoot.innerHTML = innerHtml;
                        }
                        // `this` will be the overall tooltip
                        var position = this._chart.canvas.getBoundingClientRect();
                        // Display, position, and set styles for font
                        tooltipEl.style.opacity = 1;
                        tooltipEl.style.position = 'absolute';
                        tooltipEl.style.left = position.left + window.pageXOffset + (tooltipModel.caretX - tooltipModel.width + -42) + 'px';
                        tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY + 'px';
                        tooltipEl.style.fontFamily = tooltipModel._bodyFontFamily;
                        tooltipEl.style.fontSize = tooltipModel.bodyFontSize + 'px';
                        tooltipEl.style.fontStyle = tooltipModel._bodyFontStyle;
                        tooltipEl.style.padding = tooltipModel.yPadding + 'px ' + tooltipModel.xPadding + 'px';
                        tooltipEl.style.pointerEvents = 'none';
                    },
                },
            }
        }
    },
    computed: {
        ...mapState([
            'report',
            'pathlist',
            'graphOnReport'
        ]),
        exportGraph() {
            return this.$store.state.graphOnReport.export;
        },
        printGraph() {
            return this.$store.state.graphOnReport.print;
        },
    },
    methods: {
        goToReport() {
            this.$router.push('report');
        },
        doExport() {
            this.showExportProgress = true;
            this.$store.dispatch('postAxiosNoSetter', ['/disi/reports/export',
                {
                    id: this.report.id,
                    pathId: this.exportPath,
                    includeGraph: this.graphOnReport.export
                }])
                .then(response => {
                    this.showExportProgress = false;
                    if (response.status === 200) {
                        this.showExportSuccess = true;
                        setTimeout(() => {
                            this.showExportSuccess = false;
                        }, 800)
                    }
                });
        },
        printReport() {
            this.showPrintProgress = true;
            this.$store.dispatch('postAxiosNoSetter', ['/disi/reports/print',
                {
                    id: this.report.id,
                    includeGraph: this.graphOnReport.print
                }])
                .then(response => {
                    this.showPrintProgress = false;
                    if (response.status === 200) {
                        this.showPrintSuccess = true;
                        setTimeout(() => {
                            this.showPrintSuccess = false;
                        }, 800)
                    }
                });
        },
        deleteReport() {
            this.$store.dispatch('postAxiosNoSetter', ['/disi/reports/delete', {'id': [this.report.id]}])
                .then(response => {
                    if (response.status === 200) {
                        this.goToReport();
                    }
                })
        },
    },
    mixins: [mixins],
    created() {
        this.showNoGraphMessage = false;
    },
    async mounted() {
        this.loaded = false
        try {
            this.chartdata = {
                labels: [],
                datasets: []
            }
            if (this.report.graph.tube1) {
                let dataTube1 = {
                    data: this.report.graph.tube1.map(o => o.disintegration),
                    label: this.$t('general.tube') + ' 1',
                    fill: false,
                    borderColor: '#D1D7E0',
                    borderWidth: 2,
                    pointBackgroundColor: '#D1D7E0',
                    pointBorderColor: '#D1D7E0',
                    pointRadius: 4,
                    pointHoverRadius: 6,
                };
                this.chartdata.datasets.push(dataTube1);
            }
            if (this.report.graph.tube2) {
                let dataTube2 = {
                    data: this.report.graph.tube2.map(o => o.disintegration),
                    label: this.$t('general.tube') + ' 2',
                    fill: false,
                    borderColor: '#2B6071',
                    borderWidth: 2,
                    pointBackgroundColor: '#2B6071',
                    pointBorderColor: '#2B6071',
                    pointRadius: 4,
                    pointHoverRadius: 6,
                };
                this.chartdata.datasets.push(dataTube2);
            }
            if (this.report.graph.tube3) {
                let dataTube3 = {
                    data: this.report.graph.tube3.map(o => o.disintegration),
                    label: this.$t('general.tube') + ' 3',
                    fill: false,
                    borderColor: '#FF8413',
                    borderWidth: 2,
                    pointBackgroundColor: '#FF8413',
                    pointBorderColor: '#FF8413',
                    pointRadius: 4,
                    pointHoverRadius: 6,
                };
                this.chartdata.datasets.push(dataTube3);
            }
            if (this.report.graph.tube4) {
                let dataTube4 = {
                    data: this.report.graph.tube4.map(o => o.disintegration),
                    label: this.$t('general.tube') + ' 4',
                    fill: false,
                    borderColor: '#E73F3F',
                    borderWidth: 2,
                    pointBackgroundColor: '#E73F3F',
                    pointBorderColor: '#E73F3F',
                    pointRadius: 4,
                    pointHoverRadius: 6,
                };
                this.chartdata.datasets.push(dataTube4);
            }
            if (this.report.graph.tube5) {
                let dataTube5 = {
                    data: this.report.graph.tube5.map(o => o.disintegration),
                    label: this.$t('general.tube') + ' 5',
                    fill: false,
                    borderColor: '#40BEBE',
                    borderWidth: 2,
                    pointBackgroundColor: '#40BEBE',
                    pointBorderColor: '#40BEBE',
                    pointRadius: 4,
                    pointHoverRadius: 6,
                };
                this.chartdata.datasets.push(dataTube5);
            }
            if (this.report.graph.tube6) {
                let dataTube6 = {
                    data: this.report.graph.tube6.map(o => o.disintegration),
                    label: this.$t('general.tube') + ' 6',
                    fill: false,
                    borderColor: '#638B99',
                    borderWidth: 2,
                    pointBackgroundColor: '#638B99',
                    pointBorderColor: '#638B99',
                    pointRadius: 4,
                    pointHoverRadius: 6,
                };
                this.chartdata.datasets.push(dataTube6);
            }
            //y-axis
            let testingTimeTotal;
            // // get testing time
            let testtimetube1 = this.report.results.tube1;
            let realTestingTime = this.timestampFormat(testtimetube1);

            if (this.report.testInfo.testType === 'QuickTest' || realTestingTime < this.report.method.testingTimeTimestamp) {
                testingTimeTotal = realTestingTime;
            } else {
                testingTimeTotal = this.report.method.testingTimeTimestamp;
            }
            if (testingTimeTotal) {
                let testingTimePart = testingTimeTotal / 9;
                let labels = [0];
                for (let i = 1; i <= 9; i++) {
                    labels.push(Math.round(testingTimePart * i));
                }
                let timeLabels = [];
                labels.forEach(label => {
                    timeLabels.push(this.timeFormat(label));
                })
                this.chartdata.labels = timeLabels;
                this.loaded = true
            } else {
                this.showNoGraphMessage = true;
            }
        } catch (e) {
            console.error(e);
            this.$store.commit('newError', this.$t('alerts.somethingWentWrong'));
        }
    },
}
</script>
<style scoped lang="scss">
.title-bar {
    background-color: $disiDarkblueMedium;
    height: 60px;

    p {
        font-weight: 500;
        color: white;
    }
}

.v-application p.noGraph {
    color: $disiBluegreyDark;
}
</style>