<template>
    <div class="NavigationLeftBig">
        <v-navigation-drawer permanent dark color="disiBluegreyLight" width="256px">
            <v-list nav class="pa-0 mr-4">
                <v-list-item-group active-class="disiDarkblue disiDarkblue">
                    <router-link to="/report-testinfo">
                        <v-list-item :class="{'disiDarkblue': this.$route.fullPath === '/report-testinfo'}">
                            <v-list-item-title>{{ $t('report.testInfo') }}</v-list-item-title>
                        </v-list-item>
                    </router-link>
                    <router-link to="/report-method">
                        <v-list-item :class="{'disiDarkblue': this.$route.fullPath === '/report-method'}">
                            <v-list-item-title>{{ $t('report.method') }}</v-list-item-title>
                        </v-list-item>
                    </router-link>

                    <router-link to="/report-results">
                        <v-list-item :class="{'disiDarkblue': this.$route.fullPath === '/report-results'}">
                            <v-list-item-title>{{ $t('report.results') }}</v-list-item-title>
                        </v-list-item>
                    </router-link>

                    <router-link to="/report-statistics">
                        <v-list-item :class="{'disiDarkblue': this.$route.fullPath === '/report-statistics'}">
                            <v-list-item-title>{{ $t('report.statistics') }}</v-list-item-title>
                        </v-list-item>
                    </router-link>

                    <router-link to="/report-graph">
                        <v-list-item :class="{'disiDarkblue': this.$route.fullPath === '/report-graph'}">
                            <v-list-item-title>{{ $t('report.graph') }}</v-list-item-title>
                        </v-list-item>
                    </router-link>
                </v-list-item-group>
            </v-list>
        </v-navigation-drawer>
    </div>
</template>

<script>

export default {
    name: "NavigationLeftBig",
    data () {
        return {
        }
    },
    created(){
    },
    methods: {
    }
}
</script>

<style scoped lang="scss">

.v-list-item{
    background-color: $disiDarkblueLight;
    border-radius: 0;
    height: 60px;
    text-align: center;
    font-weight: 500;
    margin-bottom: 10px;
}
.v-list--nav .v-list-item:not(:last-child):not(:only-child), .v-list--rounded .v-list-item:not(:last-child):not(:only-child){
    margin-bottom: 10px;
}
.theme--dark.v-list-item--active:hover::before, .theme--dark.v-list-item--active::before{
    opacity: 0;
}
.v-application a, .v-application a.router-link-active{
    color: white;
}
</style>